import React from 'react'
import globalStyles from '~client/globals.css'
import Export from '~client/components/exportable/Export/Export'
import FooterModule from '~client/components/exportable/FooterModule/FooterModule'
import HeaderModule from '~client/components/exportable/HeaderModule/HeaderModule'
import { verifyDcomAppUserAgent } from '../server/middleware/detectDevice'

export const Header = props => (
  isDcomAppDetected() ?
  null :
  <Export>
    <HeaderModule {...props} />
  </Export>
)

export const Footer = props => (
  isDcomAppDetected() ?
  null :
  <Export>
    <FooterModule {...props} />
  </Export>
)

// DICTENG-423 src/server/controllers/ui.js fails to detect DCOM app; questionable user-agent
function isDcomAppDetected() {
  const userAgent =
    navigator && typeof navigator.userAgent === 'string'
      ? navigator.userAgent
      : ''
  return verifyDcomAppUserAgent(userAgent)
}

export const GlobalStyles = () => <style>{globalStyles._getCss()}</style>

export { default as getSiteContext } from '~utils/getSiteContext'
export { default as React } from 'react'
// Once we are sure all export consumers are using
// ReactDomClient, we can safely remove ReactDOM
export { default as ReactDOM } from 'react-dom'
export { default as ReactDomClient } from 'react-dom/client'
