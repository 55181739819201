import MobileDetect from 'mobile-detect'

const DEVICE_DESKTOP = 'Desktop'
const DEVICE_MOBILE = 'Mobile'
const DEVICE_TABLET = 'Tablet'

/**
 * DICTENG-423 hide page header and footer for new DCOM app, 2025
 */
const USER_AGENT_DCOM_IOS = 'dcom ios'
const USER_AGENT_DCOM_ANDROID = 'dcom android'

export const verifyDcomAppUserAgent = function(userAgent = '') {
  return Boolean(
    userAgent.indexOf(USER_AGENT_DCOM_IOS) > -1 ||
    userAgent.indexOf(USER_AGENT_DCOM_ANDROID) > -1
  )
};

export default (req, res, next) => {

  const userAgent = req.headers['user-agent'] || ''
  req.isDcomApp = verifyDcomAppUserAgent(userAgent)
  req.presentedUserAgent = userAgent
  req.fastlyUserAgent = 'user-agent-placeholder'

  // when Fastly platform header is present use that
  if (req.header('platform')) {
    req.detectedDevice = req.header('platform')
    const userDashAgent = req.header('user-agent') ? req.header('user-agent') : ''
    const userNoDashAgent = req.header('userAgent') ? req.header('userAgent') : ''
    req.fastlyUserAgent = userDashAgent + ':' + userNoDashAgent
  } else {
    const md = new MobileDetect(userAgent)
    if (md.tablet()) {
      req.detectedDevice = DEVICE_TABLET
    } else if (md.phone()) {
      req.detectedDevice = DEVICE_MOBILE
    } else {
      req.detectedDevice = DEVICE_DESKTOP
    }
  }

  next()
}
